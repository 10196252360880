import "./styles.scss"

import React from "react"

import ArticleMainTail from "components/ArticleMainTail"
import ArticleSingleTail from "components/ArticleSingleTail"
import Button from "components/Button"

const NewsSection = ({ title, heading, posts }) => {
  return (
    <section className="home-blog">
      <div className="container">
        <p className="home-blog__title">{title}</p>
        <h2 className="home-blog__heading">{heading}</h2>
        <div className="home-blog__tiles">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="home-blog__main">
                <ArticleMainTail
                  title={posts[0]?.title}
                  excerpt={posts[0]?.excerpt}
                  badge="Najnowszy"
                  img={posts[0]?.acfPosts?.image}
                  url={`/blog/${posts[0]?.slug}/`}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="home-blog__articles">
                <div className="row">
                  {posts.slice(1)?.map(({ id, title, slug, acfPosts }) => {
                    return (
                      <div key={id} className="col-12 col-sm-6">
                        <ArticleSingleTail
                          title={title}
                          href={`/blog/${slug}/`}
                          img={acfPosts?.image}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button to="/blog/" className="home-blog__cta" type="button">
          Zobacz wszystkie wpisy
        </Button>
      </div>
    </section>
  )
}

export default NewsSection
